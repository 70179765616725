<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
      spinner="bar-fade-scale" />
    <v-snackbar v-model="showsnackbar" color="#005f32" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <v-card-title class="elevation-0">
          <span class="itemHeading">TARGET DETAILS</span>
          <v-spacer></v-spacer>
          <template>
            <v-flex xs12>
              <v-layout justify-end align-center class="button-container">
                <template v-if="targets.additionalDetails">
                  <v-btn v-if="targets.isVerified && !targets.isApprove" @click="openApproveTargetDialog()" dark
                    outlined color="#005f32" class="action-btn">
                    <v-icon small left>mdi-check-circle</v-icon>
                    Approve
                  </v-btn>
                  <v-btn v-if="targets.isVerified && !targets.isApprove" @click="openRejectTargetDialog()" dark outlined
                    color="orange" class="action-btn">
                    <v-icon small left>mdi-close-circle</v-icon>
                    Reject
                  </v-btn>
                </template>
                  <v-btn @click="editdialog = true" dark outlined color="primary" class="action-btn">
                    <v-icon small left>mdi-pencil</v-icon>
                    Edit
                  </v-btn>
                  <v-btn @click="deletedialog = true" dark outlined color="error" class="action-btn">
                    <v-icon small left>mdi-delete</v-icon>
                    Delete
                  </v-btn>
                </v-layout>
            </v-flex>
          </template>
        </v-card-title>

        <v-layout wrap justify-start px-4>
          <v-flex xs12>
            <v-card outlined class="pa-6">
              <v-layout wrap justify-center>
                <v-flex xs11 sm11 md11 lg12 xl12 text-left>
                  <v-flex xs12>
                    <span style="font-family: kumbhRegular; font-size: 16px;font-weight:bold;">
                      Target
                    </span>
                  </v-flex>

                  <v-flex xs12 pt-1>
                    <span style="font-family: kumbhRegular; font-size: 18px">
                      {{ targets.Targets }}
                    </span></v-flex>
                  <!-- <v-layout wrap style="font-family: kumbhRegular; font-size: 18px">
                      <v-flex pt-3 v-if="targets.TargetDate">
                        <v-chip color="#778CF7" text-color="white" class="font-weight-bold">
                          Target Date: {{ formatDate(targets.TargetDate) }}
                        </v-chip>
                      </v-flex>
                      <v-flex pt-3 v-if="targets.targetStatus">
                        <v-chip color="#4EC57A" text-color="white" class="font-weight-bold">
                          Target Status: {{ targets.targetStatus }}
                        </v-chip>
                      </v-flex>
                      <v-flex pt-3>
                        <v-chip color="#C677F7" text-color="white" class="font-weight-bold">
                          Target Score: {{ targets.targetScore }}
                        </v-chip>
                      </v-flex>
                      <v-flex pt-3 v-if="targets.weightage">
                        <v-chip color="#F17E54" text-color="white" class="font-weight-bold">
                          Target Weightage: {{ targets.weightage }}
                        </v-chip>
                      </v-flex>
                    </v-layout> -->

                  <v-layout wrap justify-start pt-4>
                    <v-flex xs12 sm6 md3 lg3 xl3 v-if="targets.TargetDate">
                      <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                        Target Date
                      </span>
                      <br>
                      <span style="font-family: kumbhRegular; font-size: 16px;">{{
                        formatDate1(targets.TargetDate) }}</span>

                    </v-flex>

                    <v-flex xs12 sm6 md3 lg3 xl3 v-if="targets.approvedBy" pt-4 pt-sm-0>
                      <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                        Approved By
                      </span>
                      <br>
                      <span v-if="targets.approvedBy" style="font-family: kumbhRegular; font-size: 16px;">
                        {{ targets.approvedBy.name }}
                      </span>


                    </v-flex>


                    <v-flex xs12 sm6 md3 lg3 xl3 v-if="targets.approvedDate" pt-4 pt-sm-4 pt-md-0>
                      <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                        Approved Date
                      </span>
                      <br>
                      <span v-if="targets.approvedDate" style="font-family: kumbhRegular; font-size: 16px;">
                        {{ formatDate1(targets.approvedDate) }}
                      </span>


                    </v-flex>

                    <v-flex xs12 sm6 md3 lg3 xl3 v-if="targets.submittedDate" pt-4 pt-sm-4 pt-md-0 pt-lg-0>
                      <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                        Submitted Date
                      </span>
                      <br>
                      <span v-if="targets.submittedDate" style="font-family: kumbhRegular; font-size: 16px;">
                        {{ formatDate1(targets.submittedDate) }}
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-start pt-4 pt-lg-2 pt-md-2 pt-sm-2>
                    <v-flex xs12 sm6 md3 lg3 xl3 v-if="targets.targetStatus" pt-2>
                      <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                        Target Status
                      </span>
                      <br>
                      <span style="font-family: kumbhRegular; font-size: 16px;">{{ targets.targetStatus }}</span>

                    </v-flex>
                    <v-flex xs12 sm6 md3 lg3 xl3 v-if="targets.weightage" pt-2>
                      <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                        Target Weightage
                      </span>
                      <br>
                      <span style="font-family: kumbhRegular; font-size: 16px;">{{
                        targets.weightage }}</span>

                    </v-flex>
                    <v-flex xs12 sm6 md3 lg3 xl3 v-if="targets.targetScore" pt-4 pt-sm-4 pt-md-2 pt-lg-2>
                      <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                        Rating Received
                      </span>
                      <br>
                      <span style="font-family: kumbhRegular; font-size: 16px;">{{
                        targets.targetScore }}</span>

                    </v-flex>
                    <v-flex xs12 sm6 md3 lg3 xl3 pt-4 pt-sm-4 pt-md-2 pt-lg-0>
                      <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                        Verification Status
                      </span>
                      <br>
                      <span v-if="!targets.isVerified" style="font-family: kumbhRegular; font-size: 16px;">
                        Not Verified
                      </span>
                      <span v-else style="font-family: kumbhRegular; font-size: 16px;">
                        Verified
                      </span>
                    </v-flex>
                    <v-flex xs12 sm6 md3 lg3 xl3 v-if="targets.isApprove" pt-4>
                      <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                        Target Approval Status
                      </span>
                      <br>
                      <span v-if="targets.isApprove" style="font-family: kumbhRegular; font-size: 16px;">
                        Approved
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-flex xs12 pt-4 v-if="targets.additionalDetails">
                    <span style="font-family: kumbhRegular; font-size: 16px;font-weight:bold;">
                      Additional Details
                    </span>
                  </v-flex>
                  <v-flex xs12 pt-1 v-if="targets.additionalDetails">
                    <span style="font-family: kumbhRegular; font-size: 18px">
                      {{ targets.additionalDetails }}
                    </span></v-flex>
                  <v-flex xs12 pt-4 v-if="targets.proofDescription">
                    <span style="font-family: kumbhRegular; font-size: 16px;font-weight:bold;">
                      Description - Support Document
                    </span>
                  </v-flex>
                  <v-flex xs12 pt-1 v-if="targets.proofDescription">
                    <span style="font-family: kumbhRegular; font-size: 18px">
                      {{ targets.proofDescription }}
                    </span></v-flex>
                  <v-layout wrap v-if="supportDocument.length > 0" py-4>
                    <v-flex xs12>
                      <span style="font-family: kumbhRegular; font-size: 16px;font-weight:bold;">
                        Support Documents
                      </span>
                    </v-flex>
                    <v-flex xs12 sm6 md4 lg4 pt-3 pr-0 pr-sm-0 pr-md-0 pr-lg-4
                      v-for="(document, index) in supportDocument" :key="index">
                      <v-card class="pa-6 fill-height" outlined color="#D9D9D933">
                        <v-layout wrap justify-start align-stretch>
                          <v-flex xs12>
                            <v-layout align-center>
                              <v-icon color="#005f32" class="mr-2">mdi-file-document</v-icon>
                              <span style="font-family: kumbhRegular; font-size: 15px; color: #005f32;">
                                {{ document }}
                              </span>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 text-end pt-2>
                            <a :href="mediaURL + document" target="_blank">
                              <v-btn dark small outlined color="#005f32" @click="viewDocument(document)"
                                class="rounded-lg btnstyle">
                                <v-icon small class="mr-1">mdi-eye</v-icon>
                                <span style="text-transform: none;font-family: kumbhRegular;">View</span>
                              </v-btn>
                            </a>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- approve  targets -->
    <v-dialog persistent v-model="acceptTargets" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Target Approval
                </span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="acceptTargets = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 align-self-center text-left>
                <span class="kumbhBold" style="color: #000; font-size: 20px">Are you sure you want to approve this
                  target?</span>
              </v-flex>
              <v-flex xs12 align-self-center text-left pt-3>
                <v-text-field class="rounded-lg" style="font-size: 17px;" v-model="targets.targetScore" required outlined dense
                  placeholder="Enter Rejection Reason" :hide-details="true"></v-text-field>
              </v-flex>
          
              <v-flex xs12 align-self-center text-right pt-5>
                <v-btn @click="approveTarget()" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Approve Target </span>
                </v-btn></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- Reject  targets -->
    <v-dialog persistent v-model="rejectTargets" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Target Rejection
                </span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="rejectTargets = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 align-self-center text-left>
                <span class="kumbhBold" style="color: #000; font-size: 20px">Are you sure you want to reject this
                  target?</span>
              </v-flex>
              <v-flex xs12 align-self-center text-left pt-3>
                <v-textarea class="rounded-lg" style="font-size: 17px;" v-model="rejectReason" required outlined dense
                  placeholder="Enter Rejection Reason" :hide-details="true"></v-textarea>
              </v-flex>

              <v-flex xs12 align-self-center text-right pt-3>
                <v-btn @click="rejectTarget()" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Reject Target </span>
                </v-btn></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- status  targets -->
    <v-dialog persistent v-model="statusTargets" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Target Status Updation
                </span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="statusTargets = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <!-- <v-flex xs12 sm12 align-self-center text-left pa-2>
               
                  <v-select v-model="targetStatus" :items="tragetStatusArray" label="Target Status" required
                    item-text="name" item-value="name" outlined dense hide-details>
                  </v-select>
                </v-flex> -->

              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-textarea v-model="additionalDetails" label="Additional Details" required hide-details outlined
                  dense></v-textarea>
              </v-flex>



              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-card outlined class="pa-2">
                  <v-flex xs12 sm12 align-self-center text-left pa-2 v-if="uploadedFileNames.length > 0"
                    style="font-family:kumbhMedium">
                    <strong>Uploaded Files:</strong>
                    <div v-for="(file, index) in uploadedFileNames" :key="index">
                      {{ file }}
                    </div>
                  </v-flex>
                  <v-flex xs12 sm12 align-self-center text-end pa-2>
                    <v-btn color="#005f32" dark small @click="$refs.proofFile.click()">
                      Upload Proofs
                      <v-icon size="18px">mdi-upload</v-icon>
                    </v-btn>
                    <input v-show="false" ref="proofFile" type="file" accept="*" multiple @change="handleFileUpload" />
                  </v-flex>
                </v-card>
              </v-flex>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-textarea v-model="proofDescription" label="Description of Uploaded Proof" required hide-details
                  outlined dense></v-textarea>
              </v-flex>

              <v-flex xs12 text-end pt-3 pa-2>
                <v-btn @click="statusTarget()" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Change Target Status </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- delete  targets -->
    <v-dialog persistent v-model="deletedialog" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Delete Target
                </span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="deletedialog = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 align-self-center text-left>
                <span class="kumbhBold" style="color: #000; font-size: 20px">Are you sure you want to delete this
                  target?</span>
              </v-flex>

              <v-flex xs12 align-self-center text-right pt-3>
                <v-btn @click="deleteItem" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Delete Target </span>
                </v-btn></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- edit targets manually -->
    <v-dialog persistent v-model="editdialog" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Edit Target Details</span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="editdialog = false, getData()">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-text-field readonly v-model="financialYear" label="Financial Year" required hide-details outlined
                  dense></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="formattedTargetDate" label="Target Date" readonly outlined hide-details dense
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="#005f32" v-model="targetDate" :allowed-dates="allowedDates" scrollable
                    @input="updateTargetDate"></v-date-picker>
                </v-menu>

              </v-flex>

              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-text-field v-model="targets.weightage" label="Target Weightage" required hide-details outlined
                  type="number" dense></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-textarea v-model="targets.Targets" label="Target" required hide-details outlined dense></v-textarea>
              </v-flex>

              <v-flex xs12 text-end pt-3 pa-2>
                <v-btn @click="edit()" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Edit Target </span>
                </v-btn></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    showsnackbar: false,
    ServerError: false,
    msg: null,
    pages: 0,
    pageCount: 1,
    appLoading: false,
    currentPage: 1,
    g: false,
    totalData: 0,
    rejectReason: "",

    rules: {
      required: (value) => !!value || "Required.",
    },

    targets: {},
    supportDocument: [],
    data: [],


    acceptTargets: false,
    rejectTargets: false,
    targetDate1: "",
    targetDate: "",
    // isTargetSheetVerified: "",
    targetWeightage: "",
    target: "",
    pickerDate: null,
    pickerDateString: '',
    menu: false,
    menu1: false,
    statusTargets: false,
    itemToDelete: null,
    deletedialog: false,
    showDialog: false,
    dialogContent: "",
    additionalDetails: "",
    proofDescription: "",
    targetStatus: "",
    proofFile: [],
    uploadedFileNames: [],
    financialYear: '',
    editingitem: "",
    editdialog: false,
    filteredTragetStatusArray: [],
    tragetStatusArray: [
      { _id: "1", name: "Met" },
      { _id: "2", name: "Not Met" },
      { _id: "3", name: "Delayed Met" },

    ],
  }),

  mounted() {
    this.getData();
  },
  computed: {
    // Automatically format the date when it changes
    formattedTargetDate() {
      return this.targets.TargetDate
        ? this.formatDate(this.targets.TargetDate)
        : "";
    },
  },
  watch: {
    currentPage() {
      this.getData();
    },
    financialYear(newVal) {
      if (newVal) {
        const [startYear] = newVal.split("-").map(Number);
        this.pickerDate = new Date(`${startYear}-04-01`);
        this.pickerDateString = this.pickerDate.toISOString().substr(0, 10);
      }
    },
    pickerDate(newVal) {
      this.pickerDateString = newVal ? newVal.toISOString().substr(0, 10) : null;
    },
    targetDate(newVal) {
      console.log("Target Date Changed:", newVal);
      this.targets.TargetDate = this.formatDate(newVal);
    },
    targetStatus() {
      this.getData();
    }

  },
  created() {
    // Access route query parameters safely
    this.financialYear = this.$route.query.financialYear || 'Default Value';
    this.TargetSheetId = this.$route.query.TargetSheetId || 'Default Value';
  },
  methods: {
    handleFileUpload(event) {
      const files = event.target.files;
      //const allowedTypes = ["application/pdf"];

      // Validate file type and limit
      for (const file of files) {
        // if (!allowedTypes.includes(file.type)) {
        //   this.msg = "Please upload only PDF files.";
        //   this.showsnackbar = true;
        //   return;
        // }
        if (this.proofFile.length >= 3) {
          this.msg = "You can upload a maximum of 3 files.";
          this.showsnackbar = true;
          return;
        }
        this.proofFile.push(file);
        this.uploadedFileNames.push(file.name);
      }
    },
    updateTargetDate(selectedDate) {
      if (selectedDate) {
        this.targets.TargetDate = this.formatDate(selectedDate); // Format date on update
      } else {
        console.error("Invalid selected date from picker:", selectedDate);
      }
    },
    openApproveTargetDialog() {
      this.acceptTargets = true; // Open the dialog
    },
    approveTarget() {
      if (this.targets.targetScore > this.targets.weightage) {
    this.msg = "Target score must not exceed the weightage!";
    this.showsnackbar = true;
    return; // Exit the function if the validation fails
  }
      axios({
        method: "POST",
        url: "/approve/target",
        data: {

          employeeId: this.$route.query.employeeId,
          targetSheetId: this.$route.query.TargetSheetId,
          targetId: this.$route.query.targetId,
          targetScore: this.targets.targetScore

        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.acceptTargets = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          console.error(err);
          this.msg = "Failed to approve!";
          this.showsnackbar = true;
        });
    },

    openRejectTargetDialog() {
      this.rejectTargets = true; // Open the dialog
    },
    rejectTarget() {
      axios({
        method: "POST",
        url: "/reject/target",
        data: {
          rejectReason: this.rejectReason,
          employeeId: this.$route.query.employeeId,
          targetSheetId: this.$route.query.TargetSheetId,
          targetId: this.$route.query.targetId,

        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.rejectTargets = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          console.error(err);
          this.msg = "Failed to reject !";
          this.showsnackbar = true;
        });
    },

    allowedDates(date) {
      if (!this.financialYear) return false;

      const [startYear, endYear] = this.financialYear.split("-").map(Number);

      const startDate = new Date(`${startYear}-04-01`);
      const endDate = new Date(`${endYear}-02-29`);

      const selectedDate = new Date(date);

      if (isNaN(selectedDate)) {
        console.error('Invalid selected date:', date);
        return false; // Reject invalid dates
      }

      return selectedDate >= startDate && selectedDate <= endDate;
    },
    // editItem(item) {
    //   this.editingitem = {
    //     ...item,
    //     TargetDate: item.TargetDate ? this.formatDate(item.TargetDate) : null, // Ensure valid date or null
    //   };
    //   this.targetDate = this.editingitem.TargetDate || null; // Initialize targetDate for v-date-picker
    //   this.editdialog = true;
    // },
    edit() {

      if (!this.targets.TargetDate) {
        this.msg = "Please enter target date.";
        this.showsnackbar = true;
        return;
      }
      if (!this.targets.weightage) {
        this.msg = "Please enter target weightage.";
        this.showsnackbar = true;
        return;
      }

      if (!this.targets.Targets) {
        this.msg = "Please enter target details.";
        this.showsnackbar = true;
        return;
      }

      this.appLoading = true;
      var data = {};
      data["financialYear"] = this.$route.query.financialYear;
      data["targetDate"] = this.targets.TargetDate;
      data["targetWeightage"] = this.targets.weightage;
      data["target"] = this.targets.Targets;
      data["targetSheetId"] = this.$route.query.TargetSheetId;
      data["targetId"] = this.$route.query.targetId;
      data["employeeId"] = this.$route.query.employeeId;

      axios({
        url: "/edit/target",
        method: "Post",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    // openStatusTargetDialog(item) {
    //   this.item = item; // Set the selected item
    //   const currentDate = new Date();
    //   const targetDate = new Date(item.TargetDate);

    //   // Filter options dynamically
    //   if (targetDate < currentDate) {
    //     this.filteredTragetStatusArray = this.tragetStatusArray.filter(
    //       (status) => status.name !== "Met"
    //     );
    //   } else {
    //     this.filteredTragetStatusArray = [...this.tragetStatusArray];
    //   }

    //   this.statusTargets = true; // Open the dialog
    // },
    statusTarget() {
      // if (!this.targetStatus) {
      //   this.msg = "Please choose Target Status.";
      //   this.showsnackbar = true;
      //   return;
      // }
      if (!this.additionalDetails) {
        this.msg = "Please enter additional details.";
        this.showsnackbar = true;
        return;
      }
      if (!this.proofDescription) {
        this.msg = "Please enter description.";
        this.showsnackbar = true;
        return;
      }
      if (!this.proofFile || this.proofFile.length === 0) {
        this.msg = "Please upload at least one proof file.";
        this.showsnackbar = true;
        return;
      }

      // Prepare form data for upload
      const formData = new FormData();
      formData.append("employeeId", localStorage.getItem("userid"));
      formData.append("targetId", this.$route.query.targetId);
      formData.append("targetSheetId", this.$route.query.TargetSheetId);
      // formData.append("targetStatus", this.targetStatus);
      formData.append("additionalDetails", this.additionalDetails);
      formData.append("proofDescription", this.proofDescription);
      this.proofFile.forEach(file => {
        formData.append('proofFile', file);
      });
      axios({
        method: "POST",
        url: "/employee/update/target/status",
        data: formData,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.statusTargets = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          console.error(err);
          this.msg = "Failed to upload file!";
          this.showsnackbar = true;
        });
    },
    // openDeleteDialog(item) {
    //   this.itemToDelete = item;
    //   this.deletedialog = true;
    // },
    // confirmDelete() {
    //   if (this.itemToDelete) {
    //     this.deleteItem(this.itemToDelete);
    //   }
    //   this.deletedialog = false;
    // },
    deleteItem() {
      var data = {};
      data["targetId"] = this.$route.query.targetId;
      data["targetSheetId"] = this.$route.query.TargetSheetId;
      data["employeeId"] = this.$route.query.employeeId;
      axios({
        url: "/delete/target",
        method: "post",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.$router.push({
              path: '/Targets/teamTarget',
              query: {
                TargetSheetId: this.$route.query.TargetSheetId,
                financialYear: this.$route.query.financialYear,
                employeeId: this.$route.query.employeeId,
              }
            });
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/employee/Target/View",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          targetId: this.$route.query.targetId,
          targetSheetId: this.$route.query.TargetSheetId,
          financialYear: this.$route.query.financialYear,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.targets = response.data.data;
          this.supportDocument = response.data.data.supportDocument;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      if (isNaN(d)) {
        console.error("Invalid date in formatDate:", date);
        return ""; // Handle invalid dates gracefully
      }
      return d.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    },
    formatDate1(date) {
  if (!date) return "";
  const d = new Date(date);
  if (isNaN(d)) {
    console.error("Invalid date in formatDate1:", date);
    return ""; // Handle invalid dates gracefully
  }
  const day = String(d.getDate()).padStart(2, "0"); // Ensure two digits
  const month = String(d.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const year = d.getFullYear();
  return `${day}-${month}-${year}`; // Format as dd-mm-yyyy
}

  },
};
</script>
<style>
.tableHead {
  font-size: 15px;
  color: #ffffff;
  font-family: kumbhMedium;
}

.action-btn {
  margin: 4px;
  min-width: 120px;
}

.button-container {
  flex-wrap: wrap;
}

/* Only for xs screens */
@media (max-width: 599px) {
  .action-btn {
    width: 100%;
    margin: 4px 0;
  }

  .button-container {
    flex-direction: column;
    align-items: stretch;
  }
}
</style>